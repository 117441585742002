import React, { PropsWithChildren, PureComponent } from 'react';

import styled from 'styled-components';

import Box from '../atoms/Box/Box';
import CheckoutPopup from '../molecules/CheckoutPopup';

import Cross2Icon from '@public/icons/cross2.svg';
import { breakpoint } from 'theme';

const BoxWrapper = styled(Box)<{ hasBorder: boolean }>`
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: ${({ smPl }) => smPl}em;
  padding-right: ${({ smPr }) => smPr}em;
  box-shadow: 0 0 1em 0 ${({ theme }) => theme.transparentColors.black25};
  ${({ hasBorder }) => hasBorder && 'border: solid 1px #cccfd0'};
  overflow: auto;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%;
  max-height: 100%;

  ${breakpoint.m`
    width: ${({ width }) => width}em;
    height: auto;
    padding-top: 1.75em;
    padding-bottom: 1.75em;
    padding-left: ${({ mPl }) => mPl}em;
    padding-right: ${({ mPr }) => mPr}em;
  `}
`;

const Cross = styled.div`
  text-align: right;
  margin-bottom: 0.5em;
  cursor: pointer;
  position: absolute;
  top: 0.75em;
  right: 0.75em;

  ${breakpoint.m`
    top: 1.125em;
    right: 1.125em;
  `}
`;

type CenterMessageProps = {
  onClose?: () => void;
  smPl: number;
  smPr: number;
  mPl: number;
  mPr: number;
  width: number;
  hasBorder: boolean;
};

export default class CenterMessage extends PureComponent<
  PropsWithChildren<CenterMessageProps>
> {
  static defaultProps = {
    smPl: 1,
    smPr: 1,
    mPl: 2.25,
    mPr: 2.25,
    width: 43,
    hasBorder: true,
  };

  render() {
    const { onClose, children, mPl, mPr, smPl, smPr, width, hasBorder } =
      this.props;

    return (
      <CheckoutPopup onClose={onClose} zIndex={20}>
        <BoxWrapper
          smPl={smPl}
          smPr={smPr}
          mPl={mPl}
          mPr={mPr}
          width={width}
          hasBorder={hasBorder}
        >
          {onClose && (
            <Cross onClick={onClose}>
              <Cross2Icon width={22} height={22} />
            </Cross>
          )}
          {children}
        </BoxWrapper>
      </CheckoutPopup>
    );
  }
}
